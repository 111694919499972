.nice-datepicker {
    font-family: Arial, sans-serif;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    width: 100%;
    font-size: medium;
  }
  
  /* Example hover effect */
  .nice-datepicker:hover {
    border-color: #888;
  }